<template>
    <div>
        <van-nav-bar
  
    left-arrow
    @click-left="onClickLeft"
    
    />
        <video :src="$route.query.videoUrl" controls="controls"  autoplay="autoplay" width="100%">

</video>
    </div>
</template>
<script>
    export default {
        methods: {
            onClickLeft() {
                 this.$router.go(-1);
            }
        },
    };
</script>